import AddIcon from "@mui/icons-material/Add";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import CircleIcon from "@mui/icons-material/Circle";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PublishIcon from "@mui/icons-material/Publish";
import SaveIcon from "@mui/icons-material/Save";
import UndoIcon from "@mui/icons-material/Undo";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Fab,
  Grid,
  Input,
  Menu,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Lottie from "lottie-react";
import { ChangeEvent, MouseEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import noElementsIllustration from "../../assets/animations/no-elements-animation.json";
import { Project } from "../../models/Project";
import { Translation, TranslationKey } from "../../models/Translation";
import { get, post, put } from "../../services/Api/ApiFunctions";
import "./TranslationsList.css";

function TranslationsList() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const { id, lang } = useParams();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [project, setProject] = useState<Project>();
  const [projectLanguage, setProjectLanguage] = useState<Translation>();
  const [projectBaseLanguage, setProjectBaseLanguage] = useState<Translation>();
  const [projectBaseLanguageKeys, setProjectBaseLanguageKeys] = useState<
    TranslationKey[]
  >([]);
  const [projectLanguageKeys, setProjectLanguageKeys] = useState<
    TranslationKey[]
  >([]);
  const [newProjectLanguageKeys, setNewProjectLanguageKeys] = useState<
    TranslationKey[]
  >([]);
  const [anchorElImport, setAnchorElImport] = useState<null | HTMLElement>(
    null
  );
  const openImport = Boolean(anchorElImport);
  const handleClickImport = (event: MouseEvent<HTMLElement>) => {
    setAnchorElImport(event.currentTarget);
  };
  const handleCloseImport = () => {
    setAnchorElImport(null);
  };
  const [update, setUpdate] = useState(true);
  const [allSelected, setAllSelected] = useState(false);
  const [selected, setSelected] = useState<TranslationKey[]>([]);
  const [selectedNew, setSelectedNew] = useState<TranslationKey[]>([]);
  const [edit, setEdit] = useState<boolean[]>([]);
  const [edited, setEdited] = useState<boolean[]>();
  const [isBaseLanguage, setIsBaseLanguage] = useState(false);
  const [importFile, setImportFile] = useState();
  const [shiftOn, setShiftOn] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    document.addEventListener("keydown", (e) => {
      if (e.key === "Shift") {
        e.preventDefault();
        setShiftOn(true);
      }
    });
  }, []);

  useEffect(() => {
    document.addEventListener("keyup", (e) => {
      if (e.key === "Shift") {
        e.preventDefault();
        setShiftOn(false);
      }
    });
  }, []);

  useEffect(() => {
    if (id && update) {
      get<Project>(`/projects/${id}`)
        .then((res) => {
          setProject(res.data);
          get<Translation>(`/projects/${id}/translations/${lang}`)
            .then((response) => {
              setProjectLanguage(response.data);
              setProjectLanguageKeys(
                response.data.keys.sort((a, b) => {
                  if (a.keyId > b.keyId) {
                    return 1;
                  }
                  if (b.keyId > a.keyId) {
                    return -1;
                  }
                  return 0;
                })
              );
              setEdit(new Array(response.data.keys.length).fill(false));
              setEdited(new Array(response.data.keys.length).fill(false));
              get<Translation>(`/projects/${id}/translations/base-language`)
                .then((r) => {
                  if (r.data.languageCode === lang) {
                    setIsBaseLanguage(true);
                  }
                  setProjectBaseLanguage(r.data);
                  setProjectBaseLanguageKeys(
                    r.data.keys.sort((a, b) => {
                      if (a.keyId > b.keyId) {
                        return 1;
                      }
                      if (b.keyId > a.keyId) {
                        return -1;
                      }
                      return 0;
                    })
                  );
                  setUpdate(false);
                  setIsLoading(false);
                })
                .catch(() => {
                  setUpdate(false);
                  setIsLoading(false);
                  toast.error(t("errors.generic-error"));
                });
            })
            .catch(() => {
              setUpdate(false);
              setIsLoading(false);
              toast.error(t("errors.generic-error"));
            });
        })
        .catch(() => {
          setUpdate(false);
          setIsLoading(false);
          toast.error(t("errors.generic-error"));
        });
    }
  }, [id, update]);

  const toggleAllSelected = (e) => {
    const { checked } = e.target;
    setAllSelected(checked);

    if (checked) {
      setSelected(projectLanguageKeys);
      setSelectedNew(newProjectLanguageKeys);
    } else {
      setSelected([]);
      setSelectedNew([]);
    }
  };

  const toggleSelected = (i: number) => (e) => {
    if (!e.target.checked) {
      setAllSelected(false);
    }

    if (shiftOn) {
      let beginAdding = -1;
      for (let j = 0; j < i; j += 1) {
        if (
          selected.findIndex(
            (x) => x.keyId === projectLanguageKeys[j].keyId
          ) !== -1
        ) {
          beginAdding = j;
          break;
        }
      }
      if (beginAdding !== -1) {
        const keysToAdd: TranslationKey[] = [];
        for (let z = beginAdding + 1; z <= i; z += 1) {
          keysToAdd.push(projectLanguageKeys[z]);
        }
        setSelected((current) => [...current, ...keysToAdd]);
      }
    } else if (
      !selected.find((x) => x.keyId === projectLanguageKeys[i].keyId)
    ) {
      setSelected([...selected, projectLanguageKeys[i]]);
    } else {
      const newSelected = selected.filter(
        (x) => x.keyId !== projectLanguageKeys[i].keyId
      );
      setSelected(newSelected);
    }
  };

  const toggleSelectedNew = (i: number) => (e) => {
    if (!e.target.checked) {
      setAllSelected(false);
    }

    if (!selectedNew.find((x) => x.keyId === newProjectLanguageKeys[i].keyId)) {
      setSelectedNew([...selected, newProjectLanguageKeys[i]]);
    } else {
      const newSelected = selectedNew.filter(
        (x) => x.keyId !== newProjectLanguageKeys[i].keyId
      );
      setSelectedNew(newSelected);
    }
  };

  const handleDraftSave = (keys: TranslationKey[], newKey = false) => {
    if (projectLanguage && keys) {
      const keysArray = keys.map(({ draft, keyId }) => ({
        value:
          draft === projectLanguageKeys.find((x) => x.keyId === keyId)?.draft ||
          newKey
            ? draft?.trim()
            : projectLanguageKeys.find((x) => x.keyId === keyId)?.draft?.trim(),
        keyId,
        status: "in",
      }));
      const keysObj = {
        keys: keysArray,
        version: projectLanguage.version,
      };
      put(`/projects/${id}/translations/${lang}/drafts`, keysObj)
        .then(() => {
          setNewProjectLanguageKeys([]);
          setAllSelected(false);
          setSelected([]);
          setSelectedNew([]);
          setUpdate(true);
          toast.success(t("translations.draft-saved"));
        })
        .catch(() => {
          toast.error(t("errors.generic-error"));
        });
    }
  };

  const handlePublish = (keys: TranslationKey[]) => {
    if (projectLanguage && keys) {
      const keysArray = keys.map(({ draft, keyId }) => ({
        value: draft?.trim(),
        keyId,
        status: "in",
      }));
      const keysObj = {
        keys: keysArray,
        version: projectLanguage.version,
      };
      put(`/projects/${id}/translations/${lang}/published`, keysObj)
        .then(() => {
          setAllSelected(false);
          setSelected([]);
          setSelectedNew([]);
          setUpdate(true);
          toast.success(t("translations.published"));
        })
        .catch(() => {
          toast.error(t("errors.generic-error"));
        });
    }
  };

  const handleNewTranslation = () => {
    setNewProjectLanguageKeys([
      ...newProjectLanguageKeys,
      { keyId: "", draft: "", published: null },
    ]);
  };

  const handleDeleteKey = (keys: TranslationKey[]) => {
    if (projectLanguage && keys) {
      const keysArray = keys.map(({ keyId }) => ({
        value: null,
        keyId,
        status: "out",
      }));
      const keysObj = {
        keys: keysArray,
        version: projectLanguage.version,
      };
      put(`/projects/${id}/translations/${lang}/published`, keysObj)
        .then(() => {
          toast.success(t("translations.key-deleted"));
          setAllSelected(false);
          setSelected([]);
          setSelectedNew([]);
          setUpdate(true);
        })
        .catch(() => {
          toast.error(t("errors.generic-error"));
        });
    }
  };

  const handleDeleteDrafts = (keys: TranslationKey[]) => {
    if (projectLanguage && keys) {
      const keysArray = keys.map(({ keyId }) => ({
        value: null,
        keyId,
        status: "in",
      }));
      const keysObj = {
        keys: keysArray,
        version: projectLanguage.version,
      };
      put(`/projects/${id}/translations/${lang}/drafts`, keysObj)
        .then(() => {
          toast.success(t("translations.draft-cleared"));
          setAllSelected(false);
          setSelected([]);
          setSelectedNew([]);
          setUpdate(true);
        })
        .catch(() => {
          toast.error(t("errors.generic-error"));
        });
    }
  };

  const handleBaseLanguageKeysValue = (i: number) => {
    if (projectBaseLanguageKeys && !projectBaseLanguageKeys[i]) {
      return "";
    }
    if (projectBaseLanguageKeys && projectBaseLanguageKeys[i]) {
      if (projectBaseLanguageKeys[i].published) {
        return projectBaseLanguageKeys[i].published;
      }
      if (projectBaseLanguageKeys[i].draft) {
        return projectBaseLanguageKeys[i].draft;
      }
    }
    return "";
  };

  const handleLanguageView = () => {
    if (project && lang) {
      const label = project.languages.find((x) => x.code === lang)?.label;
      if (label) {
        return label;
      }
      return project.languages.find((x) => x.code === lang)?.code;
    }
    return t("languages.language");
  };

  useEffect(() => {
    if (importFile) {
      const formData = new FormData();
      formData.append("file", importFile);
      post(`/projects/${id}/translations/import?langCode=${lang}`, formData)
        .then(() => {
          handleCloseImport();
          setImportFile(undefined);
          toast.success(t("translations.imported-successfully"));
          setUpdate(true);
        })
        .catch(() => {
          setImportFile(undefined);
          toast.error(t("translations.imported-error"));
        });
    }
  }, [importFile]);

  const handleTranslationsImport = (event) => {
    event.preventDefault();
    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0];
      setImportFile(file);
    }
  };

  const keyToView = (key: TranslationKey) => {
    if (key.draft !== "") {
      if (key.draft) {
        return key.draft;
      }
      if (key.published) {
        return key.published;
      }
      return "";
    }
    return "";
  };

  const handleDraftEditing = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    key: TranslationKey,
    i: number
  ) => {
    const newState = projectLanguageKeys.map((obj) => {
      if (edited) {
        const newEditedState = edited.map((o, index) => {
          if (i === index && !o) {
            return true;
          }

          return o;
        });
        setEdited(newEditedState);
      }
      if (obj.keyId === key.keyId) {
        return { ...obj, draft: e.target.value };
      }

      return obj;
    });

    setProjectLanguageKeys(newState);
  };

  const selectedCount =
    Object.values(selected).filter(Boolean).length +
    Object.values(selectedNew).filter(Boolean).length;

  const isAllSelected =
    selectedCount ===
    projectLanguageKeys.length + newProjectLanguageKeys.length;
  const isIndeterminate =
    selectedCount &&
    selectedCount !==
      projectLanguageKeys.length + newProjectLanguageKeys.length;

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          backgroundColor: "#FFFFFF",
          boxShadow: "0px 0px 25px 0px rgba(45, 69, 95, 0.1)",
          width: "100%",
          pt: "5rem",
          pl: "3rem",
          zIndex: 1,
        }}
      >
        <Grid container sx={{ mb: 2 }}>
          <Grid
            item
            md={8}
            xs={12}
            sx={{
              alignSelf: "center",
              mb: isMobile ? 2 : 0,
              display: "inline-flex",
            }}
          >
            <Button
              variant="outlined"
              sx={{
                borderRadius: "25px",
                backgroundColor: "white",
                minWidth: "40px",
                width: "40px",
                height: "40px",
                mr: 1,
              }}
              onClick={() => navigate(`/projects/${id}`)}
            >
              <ChevronLeftIcon />
            </Button>
            <Typography variant="h5" sx={{ mt: 0.75 }}>
              {project ? project.name : t("languages.project")} -&gt;{" "}
              {t("languages.languages-list")} -&gt; {handleLanguageView()}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            textAlign: isMobile ? "auto" : "right",
            mb: 2,
          }}
        >
          <Grid
            item
            md={2}
            xs={12}
            sx={{
              mb: isMobile ? 2 : 0,
              alignSelf: "center",
              textAlign: "left",
            }}
          >
            <Checkbox
              id="select-all-checkbox"
              checked={allSelected || isAllSelected}
              onChange={toggleAllSelected}
              indeterminate={!!isIndeterminate}
              inputProps={{ "aria-label": "select all keys" }}
              sx={{ ml: -1.5 }}
            />
            <label htmlFor="select-all-checkbox" style={{ fontWeight: "500" }}>
              {t("translations.select-all")}
            </label>
          </Grid>
          <Grid item md={2} xs={12} sx={{ textAlign: "left", ml: -11 }}>
            <Button
              sx={{ width: "77%" }}
              id="operations-button"
              aria-controls={open ? "operations-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              variant="contained"
              disableElevation
              endIcon={<KeyboardArrowDownIcon />}
              onClick={handleClick}
            >
              {t("translations.operations")}
            </Button>
            <Menu
              id="operations-menu"
              MenuListProps={{
                "aria-labelledby": "operations-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              elevation={0}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem
                disabled={newProjectLanguageKeys.length !== 0}
                onClick={() => {
                  handleNewTranslation();
                  handleClose();
                }}
                disableRipple
              >
                <AddIcon sx={{ mr: 1 }} />
                {t("translations.new-translation")}
              </MenuItem>
              <MenuItem
                disabled={!selected.length}
                onClick={() => {
                  const filteredSelected = selected.filter(
                    (x) => x.draft !== null && x.draft !== undefined
                  );
                  handlePublish(filteredSelected);
                  handleClose();
                }}
                disableRipple
              >
                <PublishIcon sx={{ mr: 1 }} />
                {t("translations.publish")}
              </MenuItem>
              <MenuItem
                disabled={!selected.length && !selectedNew.length}
                onClick={() => {
                  const filteredSelectedNew = selectedNew.filter(
                    (x) => x.keyId
                  );
                  const filteredSelected = selected.filter(
                    (x) => x.draft !== null && x.draft !== undefined
                  );
                  if (selectedNew) {
                    handleDraftSave([
                      ...filteredSelected,
                      ...filteredSelectedNew,
                    ]);
                  } else {
                    handleDraftSave(filteredSelected);
                  }
                  handleClose();
                }}
                disableRipple
              >
                <SaveIcon sx={{ mr: 1 }} />
                {t("translations.save-drafts")}
              </MenuItem>
              <MenuItem
                disabled={!selected.length}
                onClick={() => {
                  const filteredSelected = selected.filter(
                    (x) => x.draft !== null && x.draft !== undefined
                  );
                  handleDeleteDrafts(filteredSelected);
                  handleClose();
                }}
                disableRipple
              >
                <ClearAllIcon sx={{ mr: 1 }} />
                {t("translations.clear-drafts")}
              </MenuItem>
              <MenuItem
                disabled={!selected.length}
                onClick={() => {
                  const filteredSelected = selected.filter(
                    (x) =>
                      (x.draft !== null && x.draft !== undefined) ||
                      (x.published !== null && x.published !== undefined)
                  );
                  handleDeleteKey(filteredSelected);
                  handleClose();
                }}
                disableRipple
              >
                <DeleteIcon sx={{ mr: 1 }} />
                {t("translations.delete")}
              </MenuItem>
            </Menu>
          </Grid>
          <Grid item md={2} xs={12} sx={{ ml: -20 }}>
            <Button
              variant="contained"
              sx={{ color: "secondary.main" }}
              onClick={handleClickImport}
              id="import-button"
              aria-controls={openImport ? "import-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openImport ? "true" : undefined}
              disableElevation
              endIcon={<KeyboardArrowDownIcon />}
            >
              {t("translations.import")}
            </Button>
            <Menu
              id="import-menu"
              MenuListProps={{
                "aria-labelledby": "import-button",
              }}
              anchorEl={anchorElImport}
              open={openImport}
              onClose={handleCloseImport}
              elevation={0}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <label htmlFor="json-upload-file">
                <Input
                  inputProps={{ accept: "application/json" }}
                  id="json-upload-file"
                  type="file"
                  onChange={(e) => handleTranslationsImport(e)}
                  style={{ display: "none" }}
                />{" "}
                <MenuItem disableRipple>JSON</MenuItem>
              </label>
              <label htmlFor="csv-upload-file">
                <Input
                  inputProps={{ accept: "text/csv" }}
                  id="csv-upload-file"
                  type="file"
                  onChange={(e) => handleTranslationsImport(e)}
                  style={{ display: "none" }}
                />{" "}
                <MenuItem disableRipple>CSV</MenuItem>
              </label>
              <label htmlFor="xml-upload-file">
                <Input
                  inputProps={{ accept: "application/xml" }}
                  id="xml-upload-file"
                  type="file"
                  onChange={(e) => handleTranslationsImport(e)}
                  style={{ display: "none" }}
                />{" "}
                <MenuItem disableRipple>XML</MenuItem>
              </label>
              <label htmlFor="strings-upload-file">
                <Input
                  inputProps={{ accept: "text/strings" }}
                  id="strings-upload-file"
                  type="file"
                  onChange={(e) => handleTranslationsImport(e)}
                  style={{ display: "none" }}
                />{" "}
                <MenuItem disableRipple>STRINGS</MenuItem>
              </label>
              <label htmlFor="xliff-upload-file">
                <Input
                  inputProps={{ accept: " application/xliff+xml" }}
                  id="xliff-upload-file"
                  type="file"
                  onChange={(e) => handleTranslationsImport(e)}
                  style={{ display: "none" }}
                />{" "}
                <MenuItem disableRipple>XLIFF</MenuItem>
              </label>
            </Menu>
          </Grid>
          <Grid item md={8} />
        </Grid>
      </Box>
      <Box
        sx={{ width: "95%", margin: "14rem auto" }}
        className="layoutMinHeight"
      >
        {projectBaseLanguage &&
          projectBaseLanguageKeys &&
          projectLanguageKeys &&
          projectLanguageKeys.map((key, i) => (
            <Card
              sx={{
                borderRadius: "10px",
                boxShadow: "0px 0px 12px 0px rgba(55, 73, 72, 0.06)",
                mb: 2,
              }}
              key={key.keyId}
            >
              <CardContent>
                <Grid container sx={{ placeItems: "center" }}>
                  <Grid item xs={6} sx={{ display: "grid" }}>
                    <Box sx={{ display: "inline-flex" }}>
                      <Checkbox
                        checked={
                          selected.findIndex((x) => x.keyId === key.keyId) !==
                            -1 || allSelected
                        }
                        onChange={toggleSelected(i)}
                        sx={{ mr: 1 }}
                      />
                      <Typography sx={{ mt: 1 }}>{key.keyId}</Typography>
                    </Box>
                    {projectBaseLanguage &&
                      projectBaseLanguageKeys &&
                      projectBaseLanguageKeys.length &&
                      !isBaseLanguage && (
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Typography sx={{ mb: 0.5, mr: 2 }}>
                            {projectBaseLanguage.languageCode}
                          </Typography>
                          <TextField
                            disabled
                            variant="filled"
                            label={t("translations.base-language-value")}
                            value={handleBaseLanguageKeysValue(i)}
                            sx={{ mb: 1, width: "50%" }}
                          />
                        </Box>
                      )}
                  </Grid>
                  <Grid item xs={1} sx={{ textAlign: "center" }}>
                    <Typography>{lang}</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    {key.draft !== null || edit[i] ? (
                      <Box sx={{ display: "flex" }}>
                        <TextField
                          fullWidth
                          multiline
                          className="value-field"
                          variant="filled"
                          label={t("translations.draft-value")}
                          value={keyToView(key)}
                          onChange={(e) => handleDraftEditing(e, key, i)}
                          sx={{ mb: 1, zIndex: 0 }}
                          InputProps={{
                            endAdornment: (
                              <Button
                                disabled={edited ? !edited[i] : false}
                                variant="contained"
                                className="value-field-button"
                                sx={{ minHeight: "56px", height: "100%" }}
                                onClick={() => handleDraftSave([key])}
                              >
                                <SaveIcon />
                              </Button>
                            ),
                          }}
                        />
                        <CircleIcon
                          sx={{ ml: 2, alignSelf: "center" }}
                          style={{
                            color: key.draft !== null ? "#FFC100" : "#455A64",
                          }}
                        />
                      </Box>
                    ) : null}
                    <Box sx={{ display: "flex" }}>
                      <TextField
                        fullWidth
                        disabled
                        className="value-field"
                        variant="filled"
                        label={t("translations.published-value")}
                        value={key.published ? key.published : ""}
                        sx={{ zIndex: 0 }}
                        InputProps={{
                          endAdornment: (
                            <Button
                              type="submit"
                              variant="contained"
                              className="value-field-button"
                              sx={{ minHeight: "56px" }}
                              onClick={() => {
                                const newState = edit.map((obj, index) => {
                                  if (i === index && !obj) {
                                    return true;
                                  }
                                  if (i === index && obj) {
                                    key.draft = null;
                                    return false;
                                  }

                                  return obj;
                                });
                                setEdit(newState);
                              }}
                            >
                              {edit[i] ? <UndoIcon /> : <EditIcon />}
                            </Button>
                          ),
                        }}
                      />
                      <CircleIcon
                        sx={{ alignSelf: "center", ml: 2 }}
                        style={{ color: "#71C02B" }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          ))}
        {projectLanguageKeys &&
          !projectLanguageKeys.length &&
          projectBaseLanguageKeys &&
          !projectBaseLanguageKeys.length &&
          newProjectLanguageKeys &&
          !newProjectLanguageKeys.length &&
          !isLoading && (
            <Box sx={{ textAlign: "center" }}>
              <Lottie
                animationData={noElementsIllustration}
                style={{
                  width: "256px",
                  margin: "0 auto",
                }}
              />
              <Typography variant="h5">
                {t("translations.no-translations")}
              </Typography>
              <Typography variant="h5">
                {t("translations.add-translation")}
              </Typography>
              <Fab
                color="primary"
                aria-label="add"
                sx={{ mt: 2 }}
                onClick={() => handleNewTranslation()}
              >
                <AddIcon />
              </Fab>
            </Box>
          )}
        {newProjectLanguageKeys &&
          newProjectLanguageKeys.map((newKey, i) => (
            <Card
              sx={{
                borderRadius: "10px",
                boxShadow: "0px 0px 12px 0px rgba(55, 73, 72, 0.06)",
                mb: 2,
              }}
              // eslint-disable-next-line react/no-array-index-key
              key={i}
            >
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={5.5}>
                    <Box sx={{ display: "inline-flex", width: "100%" }}>
                      <Checkbox
                        checked={
                          selectedNew.findIndex(
                            (x) => x.keyId === newKey.keyId
                          ) !== -1 || allSelected
                        }
                        onChange={toggleSelectedNew(i)}
                        sx={{ mr: 1 }}
                      />
                      <TextField
                        fullWidth
                        variant="standard"
                        label={t("translations.key")}
                        value={newKey.keyId}
                        onChange={(e) => {
                          const newState = newProjectLanguageKeys.map(
                            (obj, index) => {
                              if (i === index) {
                                return { ...obj, keyId: e.target.value };
                              }

                              return obj;
                            }
                          );

                          setNewProjectLanguageKeys(newState);
                        }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={5.5}>
                    <TextField
                      fullWidth
                      multiline
                      variant="standard"
                      label={t("translations.value")}
                      value={newKey.draft}
                      onChange={(e) => {
                        const newState = newProjectLanguageKeys.map(
                          (obj, index) => {
                            if (i === index) {
                              return { ...obj, draft: e.target.value };
                            }

                            return obj;
                          }
                        );

                        setNewProjectLanguageKeys(newState);
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={1} sx={{ textAlign: "center" }}>
                    <Button
                      variant="contained"
                      disabled={!newKey.keyId || !newKey.draft}
                      sx={{
                        borderRadius: "25px",
                        mt: 1,
                      }}
                      onClick={() => handleDraftSave([newKey], true)}
                    >
                      <SaveIcon />
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          ))}
        {(projectBaseLanguageKeys.length !== 0 ||
          projectLanguageKeys.length !== 0) &&
          newProjectLanguageKeys.length === 0 && (
            <Box sx={{ textAlign: "center" }}>
              <Fab
                color="primary"
                aria-label="add"
                sx={{ mt: 2, zIndex: 0 }}
                onClick={() => handleNewTranslation()}
              >
                <AddIcon />
              </Fab>
            </Box>
          )}
      </Box>
    </>
  );
}

export default TranslationsList;
